<template>
  <div class="sender-container">
    <div class="box">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="#d31408" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,130.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
        <h1>Houve um erro ao verificar o remetente</h1>
        <p>
          Infelizmente houve um problema ao verificar seu remetente. Por favor, entre em contato com o nosso suporte.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    }
  }
}
</script>


<style lang="scss" scoped>
  .sender-container {
    background-color: var(--red2);
    height: 100vh;
    display: grid;
    place-items: center;
  }
  .box {
    max-width: 450px;
    width: 80%;
    height: 420px;
    border-radius: 15px;
    background-color: white;
    padding: 40px;
    text-align: center;
    animation: expand .2s forwards ease-in-out;
    display: grid;
    place-items: center;
    h1, p {
      font-weight: 600;
      color: var(--gray05);
      margin-top: 30px;
    }

    h1{
      font-size: 24px;
    }

    p{
      font-size: 16px;
    }
    @keyframes expand {
      from {
        transform: scale(1.2);
      }
      to{
        transform: scale(1);
      }
    }
  }

</style>
